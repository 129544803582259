import React, {useState} from "react";
import "./OneMessage.scss"
import {Divider} from "@material-ui/core";
import classNames from 'classnames'
const Send = "send"
const Receive = "Receive"

export default function OneMessage(props) {
    const [message,setMessage] = useState(props.message)
    const [isFirst,setFirst] = useState(props.first)

    return<React.Fragment>
        {isFirst?null:<Divider variant="middle" />}
        <div className={classNames({
            "one":true,
            "s":message.direction===Send,
            "r":message.direction!==Send
        })}>
            <span>
                {message.direction===Send?"<-":"->"}
            </span>
            <span>
                {message.message}
            </span>
        </div>
    </React.Fragment>
}
