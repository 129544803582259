import React, {useState} from "react";
import OneMessage from "./OneMessage";


export default function Messages(props) {

    return<div>
        {props.messages.map((message,i)=>{
            return <OneMessage key={i} first={i === 0} message={message} />
        })}
    </div>
}
