import React, {useEffect, useState} from 'react';
import {Button, Snackbar, TextField, Typography} from "@material-ui/core";
import axios from "axios";
import MuiAlert from '@material-ui/lab/Alert';
import {Alert} from "@material-ui/lab";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Input from "./Input";
import "./Page.scss";
import T from "./T";
import Messages from "./Messages";
import {fromJS, List, Map} from "immutable";

const endpoint = "https://apifurry.ø.win";

export default function Page() {

    const [messages,setMessages]=useState([]);

    useEffect(()=>{
        async function a(){
            while (true){
                let start = now()
                let newMessages = await get()

                console.log("nm",newMessages)

                for (let i in newMessages){
                    newMessages[i]={...newMessages[i],direction:"receive"}
                }
                setMessages((messages) => {
                    console.log("m",messages)
                    console.log("all",[...messages,...newMessages])
                    return [...messages,...newMessages]
                })
                let finish = now()
                let diff = 10*1000-(finish-start);
                if (diff>0){
                    await new Promise(resolve => setTimeout(resolve, diff));
                }
            }
        }
        a()
    },[])

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: 'dark',
                },
            }),
        [],
    );
    const send = async (content)=>{
        try{
            if (content.length===0)return false;
            let response = await axios.post(endpoint+"/send",content)
            return response.status===200
        }catch (e){
            return false;
        }

    }

    const noMessage = <div style={{marginTop:"30px"}}>
        <Typography variant="h3" style={{width:"100%",textAlign:"center"}}>
            福瑞看门
        </Typography>
    </div>

    const sent = (message)=>{
        setMessages([...messages,{
            id:-1,
            message,
            direction:"send"
        }])
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <div className={"area"}>
                    {messages.length===0?noMessage:<Messages messages={messages}/>}
                </div>
                <div className={"input"}>
                    <Input send={send} sent={sent}/>
                </div>
        </ThemeProvider>
        </div>

    );
}


async function get(){

    let last = getLast()

    try{
        let response = await axios.get(endpoint+"/get",{params:{offset: last+1}})
        for (let i in response.data){
            last=Math.max(response.data[i].id,last)
        }
        window.localStorage.setItem("last",""+last)
        window.localStorage.setItem("time",""+now())
        console.log("?",response.data)
        return response.data
    }catch (e){
        console.log(e.response)
        if(e.response && e.response.status===409){
            await new Promise(resolve => setTimeout(resolve, 15000));
        }
        return []
    }
}


function getLast(){
    let last = window.localStorage.getItem("last")
    let time = window.localStorage.getItem("time")
    if (last) {
        last = parseInt(last)
    }else {
        last=0
    }

    if (time){
        time = parseInt(time)
        let current = now()
        if (current-time>30*60*1000){
            return 0;
        }else {
            return last;
        }
    }else {
        return 0;
    }

}


function now(){
    return new Date().getTime()
}
