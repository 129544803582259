import React, {useEffect, useState} from "react";
import {CircularProgress, IconButton, InputAdornment, TextField} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import "./Input.scss"
import {Send, Visibility} from "@material-ui/icons";

export default function Input(props){

    const [content, setContent] = useState("");
    const [sending, setSending] = useState(false);

    const s = async ()=>{
        setSending(true)
        const success = await props.send(content)
        if (success){
            props.sent(content)
            setContent("")

        }
        setSending(false)
    }


    return<React.Fragment>
        <div className={"send"} >
            <TextField
                style={{width:"100%",borderColor:"azure"}}
                id="outlined-textarea"
                label="啥事？"
                placeholder="你事谁..."
                variant="filled"
                onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        s()
                    }
                }}
                onChange={e=>setContent(e.target.value)}
                value={content}
                color={"primary"}
                disabled={sending}
            />
            <div className={"icon"}>
                {sending?
                    <CircularProgress className={"progress"} />:
                    <IconButton aria-label="send" onClick={s} disabled={sending}>
                        <Send />
                    </IconButton>
                }

            </div>
        </div>
    </React.Fragment>
}
