import React from "react";

export default class T extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0
        };
    }


    render() {
        return (
            <div style={{width:"100px",height:"100px",background:"black"}}>
            </div>
        );
    }
}
